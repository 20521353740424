import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { Alert, AlertTitle } from '@mui/lab';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { StylesEdit } from '../../StylesEdit';
import { ITypeDoc } from '../../../store/officialDoc/types';
import { CreateTypeDoc, DeleteTypeDoc, UpdateTypeDoc } from '../../../store/officialDoc/actions';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { WarningModal } from '../../modals';
import { APPROVED } from '../../../utils/consts';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface PropsType {
  type: 'edit' | 'new';
  data?: ITypeDoc;
  handleCancel: () => void;
}

export const EditTypeDoc: React.FC<PropsType> = React.memo(({ type, data, handleCancel }) => {
  const [name, setName] = React.useState(data?.name || '');
  const [shortName, setShortName] = React.useState(data?.short_name || '');
  const [errors, setErrors] = React.useState('');
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const [approved, setApproved] = React.useState(data ? data.approved : APPROVED.PUBLISH);

  const { OfficialDoc } = useSelector((store: AppStore) => store);

  const dispatch = useDispatch();
  const classes = StylesEdit();

  const handleRemove = (id: number) => {
    dispatch(DeleteTypeDoc.request({ id, callBack: (success) => success && handleCancel() }));
  };

  const onSubmit = () => {
    setErrors('');

    if (!name || !shortName) return setErrors('Заповніть поля');

    const reqData = {
      id: 0,
      data: {
        name,
        shortName,
        approved,
      },
      callBack: (success: boolean) => {
        if (!success) {
          setErrors('Сталася помилка');
        } else {
          handleCancel();
        }
      },
    };

    if (data && type === 'edit') reqData['id'] = data.id;

    if (type === 'new') dispatch(CreateTypeDoc.request(reqData));
    if (data && type === 'edit') dispatch(UpdateTypeDoc.request(reqData));
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper} style={{ alignItems: 'center' }}>
        <Box className={classes.header}>
          <Typography component='h1' variant='h5'>
            {type.includes('new') ? `Створити тип` : `Редагувати тип`}
          </Typography>
          <IconButton
            aria-label='delete'
            style={{ color: data?.approved ? 'red' : '' }}
            onClick={() => data?.approved && setIsModal({ id: data.id })}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
        <Box className={classes.textField}>
          <FormControlLabel
            control={<Checkbox checked={approved === APPROVED.PUBLISH} />}
            onChange={() => setApproved(approved === APPROVED.PUBLISH ? APPROVED.CREATE : APPROVED.PUBLISH)}
            label='Публікувати'
          />
        </Box>
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Назва'
          variant='outlined'
          value={name}
          error={!!errors && !name}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setName(event.target.value);
          }}
        />
        <TextField
          required
          size='small'
          id='outlined-basic-shortName'
          label='Скорочена назва'
          variant='outlined'
          value={shortName}
          error={!!errors && !shortName}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setShortName(event.target.value);
          }}
        />
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={OfficialDoc.loading} onClick={onSubmit}>
            {OfficialDoc.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {errors ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
      </div>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`тип документа`}
          open={!!isModal}
        />
      ) : null}
    </Box>
  );
});
